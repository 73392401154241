import { APPS } from '@axeedge/go-shared-utils';

export const APP_NAME = APPS.go_portal_pupil;
export const APP_NAME_US = APPS.boom_math_portal_pupil;

export const SURVEY_STATUS = {
    new: 0,
    stated: 1,
    completed: 2
}

export const REWARD_CARD_PACKS = [
    {
        key: 'dragons',
        pack: 'Dragons',
    },
    {
        key: 'ancient_greece',
        pack: 'Ancient Greece',
    },
    {
        key: 'awesome_animals',
        pack: 'Awesome Animals',
    },
    {
        key: 'sea_creatures',
        pack: 'Sea Creatures',
    },
    {
        key: 'spooky',
        pack: 'Spooky',
    },
    {
        key: 'ancient_egypt',
        pack: 'Ancient Egypt'
    },
    {
        key: 'competition_winners_2021',
        pack: 'Competition Winners 2021'
    },
    {
        key: 'insects',
        pack: 'Insects'
    },
    {
        key: 'dinosaurs',
        pack: 'Dinosaurs'
    },
    {
        key: 'space',
        pack: 'Space'
    },
    {
        key: 'pets',
        pack: 'Pets'
    },
    {
        key: 'vehicles',
        pack: 'Vehicles'
    },
    {
        key: 'competition_winners_2022',
        pack: 'Competition Winners 2022'
    },
    {
        key: 'jobs',
        pack: 'Jobs'
    },
    {
        key: 'mythical_creatures',
        pack: 'Mythical Creatures'
    },
    {
        key: 'emoji',
        pack: 'Emoji'
    },
    {
        key: 'magic_supplies',
        pack: 'Magic Supplies'
    },
    {
        key: 'ollie_the_alien',
        pack: 'Ollie the Alien'
    },
    {
        key: 'precious_minerals',
        pack: 'Precious Minerals'
    },

    {
        key: 'animal_life',
        pack: 'Animal Life'
    },
]